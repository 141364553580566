import trim from 'lodash.trim';
import * as $url from '../../public/url';
import http from '../../public/http';
import rememberMe from '../../public/remember-me';
import ticket from '../../public/ticket';
import session from '../../public/session';
import wechat from '../../public/wechat';
import * as constant from '../../public/constant';

const router = {
  state: {
    data: {
      errorMsg: '',
      error: false,
      loading: false,
      appInfo: {},
      loginResult: {},
      clientId: '',
      responseType: '',
      timer: 60,
      isTenant: false,
      isFast: false,
      isRememberMe: true,
      captchaUrl: '',
      isCaptcha: false,
    },
    phoneLogin: {
      tenant: '',
      appKey: '',
      phone: '',
      code: '',
      captcha: '',
      bindWeOpenId: true,
      weOpenId: ''
    }
  },
  mutations: {
    copyRememberMePhone(state) {
      const data = rememberMe.getPhone(state.phoneLogin.appKey);
      if (data) {
        state.phoneLogin.tenant = data.tenant ? data.tenant : '';
        state.phoneLogin.phone = data.phone ? data.phone : '';
      }
    },

    rememberMe(state, payload) {
      state.data.isRememberMe = payload;
      rememberMe.setRemember(state.phoneLogin.appKey, payload);
    },

    getAppInfo(state, payload) {
      state.data.appInfo = payload;
      state.data.isTenant = state.data.appInfo.tenant;
      // if (!state.data.isTenant) {
      //   state.userLogin.tenant = 'KTM';
      //   state.phoneLogin.tenant = 'KTM';
      // }
      state.phoneLogin.tenant = 'KTM';
    },

    timerDown(state) {
      const clearTimer = setInterval(() => {
        state.data.timer -= 1;
        if (state.data.timer === 0) {
          clearInterval(clearTimer);
        }
      }, 1000);
    },
  },

  actions: {
    init({ state }, self) {
      return new Promise((resolve) => {
        const queryStringParam = $url.getQueryString(window.location.href);
        const from = queryStringParam.from;
        const error = queryStringParam.error;
        const openId = queryStringParam.openid;
        if (from && from === 'iam') {
          if (error && error === '10') {
            state.data.error = true;
            state.data.errorMsg = '获取微信授权失败，请确认是否关注当前公众号';
            self.$dialog({ message: state.data.errorMsg, title: '提示' });
            resolve(false);
            return;
          }

          wechat.setOpenId(openId);
          state.data.isRememberMe = rememberMe.isRemember();
          state.phoneLogin.appKey = wechat.getAppKey();
          state.phoneLogin.redirectUrl = wechat.getRedirectUrl();
          resolve(true);
          return;
        }

        if (!queryStringParam || !queryStringParam.clientId || !queryStringParam.redirectUrl) {
          state.data.error = true;
          state.data.errorMsg = '当前登录的应用请求参数配置错误';
          self.$dialog({ message: state.data.errorMsg, title: '提示' });
          resolve(false);
          return;
        }
        state.data.isRememberMe = rememberMe.isRemember();
        state.phoneLogin.appKey = queryStringParam.clientId;
        state.phoneLogin.redirectUrl = queryStringParam.redirectUrl;
        wechat.setAppKey(state.phoneLogin.appKey);
        wechat.setRedirectUrl(state.phoneLogin.redirectUrl);
        if (wechat.isWeChat() && !wechat.getOpenId()) {
          window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe5f0d8beb5e98ea9&redirect_uri=https%3A%2F%2Fssowe.kaitaiming.com%2Fsso-platform%2Fwechat%2Fauthorize&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect';
          resolve(false);
        }
        resolve(true);
      });
    },

    copyRememberMePhone({ commit }) {
      commit('copyRememberMePhone');
    },

    getAppInfo({ state, commit }, self) {
      return new Promise((resolve) => {
        if (state.data.error) {
          self.$notify({ message: state.data.errorMsg, type: 'warning' });
          return;
        }

        const toast = self.$toast({ message: '加载中...', type: 'loading', duration: 0, forbidClick: true });
        const url = $url.getBackendApiUrl('preauth/init');
        const data = { appKey: state.phoneLogin.appKey, redirectUrl: state.phoneLogin.redirectUrl, ticketRequests: ticket.getTickets(), weOpenId: wechat.getOpenId() };
        http.post(url, data, $url.default_headers).then((res) => {
          toast.close();

          if (res.status === 200 && res.data.success && res.data.data && res.data.data.data) {
            window.location.href = decodeURIComponent(`${res.data.data.data}`);
            return;
          }

          if (res.status === 200 && res.data.success && res.data.data && res.data.data.name) {
            if (!res.data.data.bindWeOpenId) {
              self.$dialog({ message: '为了提供更好的服务,系统会将微信授权的公开信息与当前登录的用户绑定,检测到您的微信与系统账号未进行关联绑定,请保证当前登录是您本人的账号', title: '提示' });
            }
            commit('getAppInfo', res.data.data);

            if (state.data.appInfo.applyStyle) {
              commit('changeConfig', state.data.appInfo.applyStyle);
              if (state.data.appInfo.applyStyle.customCss) {
                commit('loadCssCode');
              }
            }
            session.setSessionId(res.data.data.sessionId);
            resolve(res.data.data);
          } else if (res.status === 200 && !res.data.success) {
            state.data.error = true;
            state.data.errorMsg = res.data.errMessage;
            self.$notify({ message: state.data.errorMsg, type: 'warning' });
          } else {
            state.data.error = true;
            state.data.errorMsg = '无法识别的应用';
            self.$notify({ message: state.data.errorMsg, type: 'warning' });
          }
        }).catch((e) => {
          console.error(e);
          toast.close();
          state.data.error = true;
          state.data.errorMsg = '内部服务错误';
          self.$notify({ message: state.data.errorMsg, type: 'warning' });
        });
      });
    },

    send({ state, commit }, self) {
      return new Promise((resolve) => {
        if (state.data.error) {
          self.$notify({ message: state.data.errorMsg, type: 'warning' });
          return;
        }

        const openId = wechat.getOpenId();
        if (!openId || openId === 'undefined' || openId === 'null') {
          state.data.error = false;
          state.data.errorMsg = '获取微信授权失败，请确认是否关注当前公众号';
          self.$dialog({ message: state.data.errorMsg, title: '提示' });
          return;
        }

        const phone = trim(state.phoneLogin.phone);
        if (!phone) {
          state.data.error = false;
          state.data.errorMsg = '请输入手机号';
          self.$notify({ message: state.data.errorMsg, type: 'warning' });
          self.$refs.phoneInput.focus();
          return;
        }

        if (phone) {
          const match = /^1\d{10}$/.test(phone);
          if (!match) {
            state.data.error = false;
            state.data.errorMsg = '手机号格式错误';
            self.$notify({ message: state.data.errorMsg, type: 'warning' });
            self.$refs.phoneInput.focus();
            return;
          }
        }

        rememberMe.setPhone(state.phoneLogin.appKey, state.phoneLogin);
        const toast = self.$toast({ message: '短信验证码发送中...', type: 'loading', duration: 0, forbidClick: true });
        const url = $url.getBackendApiUrl('authencation/oauth2/login/send/message/code');
        http.post(url, state.phoneLogin, { headers: { 'Content-type': 'application/json;charset=UTF-8', tenant: state.phoneLogin.tenant } }).then((res) => {
          if (res.status === 200 && res.data.success) {
            toast.close();
            self.$toast({ message: '短信验证码发送成功', type: 'success', forbidClick: true });
            state.data.error = false;
            state.data.errorMsg = '';
            state.data.timer = 59;
            commit('timerDown');
          } else if (res.status === 200 && !res.data.success && res.data.errCode === 'PHONE_SEND_MESSAGE_CODE_COUNT_LIMIT') {
            state.data.error = false;
            state.data.errorMsg = '请不要频繁操作获取验证码';
            self.$toast({ message: '请不要频繁操作获取验证码', type: 'fail', forbidClick: true, icon: 'clear' });
            state.data.timer = 59;
            commit('timerDown');
          } else {
            state.data.error = false;
            state.data.errorMsg = res.data.errMessage;
            self.$toast({ message: res.data.errMessage, type: 'fail', forbidClick: true, icon: 'clear' });
            resolve();
          }
        }).catch((e) => {
          console.error(e);
          toast.close();
          self.$toast({ message: '短信验证码获取失败', type: 'fail', forbidClick: true, icon: 'clear' });
        });
      });
    },

    loginByPhone({ state }, self) {
      return new Promise((resolve) => {
        if (state.data.error) {
          self.$notify({ message: state.data.errorMsg, type: 'warning' });
          return;
        }

        const openId = wechat.getOpenId();
        if (!openId || openId === 'undefined' || openId === 'null') {
          state.data.error = false;
          state.data.errorMsg = '获取微信授权失败，请确认是否关注当前公众号';
          self.$dialog({ message: state.data.errorMsg, title: '提示' });
          return;
        }
        state.phoneLogin.weOpenId = openId;

        const phone = trim(state.phoneLogin.phone);
        if (!phone) {
          state.data.error = false;
          state.data.errorMsg = '请输入手机号';
          self.$notify({ message: state.data.errorMsg, type: 'warning' });
          self.$refs.phoneInput.focus();
          return;
        }

        if (phone) {
          const match = /^1\d{10}$/.test(phone);
          if (!match) {
            state.data.error = false;
            state.data.errorMsg = '手机号格式错误';
            self.$notify({ message: state.data.errorMsg, type: 'warning' });
            self.$refs.phoneInput.focus();
            return;
          }
        }

        if (!trim(state.phoneLogin.code)) {
          state.data.error = false;
          state.data.errorMsg = '请输入验证码';
          self.$notify({ message: state.data.errorMsg, type: 'warning' });
          self.$refs.codeInput.focus();
          return;
        }

        rememberMe.setPhone(state.phoneLogin.appKey, state.phoneLogin);
        const toast = self.$toast({ message: '登录中...', type: 'loading', duration: 0, forbidClick: true });
        const url = $url.getBackendApiUrl('authencation/oauth2/login/phone');
        http.post(url, state.phoneLogin, { headers: { 'Content-type': 'application/json;charset=UTF-8', tenant: state.phoneLogin.tenant } }).then((res) => {
          toast.close();
          if (res.status !== 200) {
            state.data.error = false;
            state.data.errorMsg = '登录失败';
            self.$toast({ message: state.data.errorMsg, type: 'fail', forbidClick: true, icon: 'clear' });
            resolve(constant.CAPTCHA);
            return;
          }

          if (!res.data.success) {
            state.data.error = false;
            state.data.errorMsg = res.data.errMessage;
            self.$toast({ message: state.data.errorMsg, type: 'fail', forbidClick: true, icon: 'clear' });
            resolve(constant.CAPTCHA);
            return;
          }

          if (res.data.success) {
            ticket.setTicket(res.data.data);
            window.location.href = decodeURIComponent(`${res.data.data.data}`);
          }
        }).catch((e) => {
          console.error(e);
          state.data.error = false;
          state.data.errorMsg = '登录失败';
          toast.close();
          self.$toast({ message: '登录失败', type: 'fail', forbidClick: true, icon: 'clear' });
        });
      });
    },

    triggerCaptcha({ state }) {
      return new Promise((resolve) => {
        state.data.loading = true;
        const url = $url.getBackendApiUrl(`captcha/trigger?appKey=${state.phoneLogin.appKey}`);
        http.get(url).then((res) => {
          if (res.data.success && res.data.data) {
            state.data.isCaptcha = true;
            resolve(true);
          }

          if (res.data.success && res.data.data === false) {
            state.data.isCaptcha = false;
          }

          if (res.data.success === false) {
            state.data.isCaptcha = false;
            state.data.error = false;
            state.data.errorMsg = res.data.errMessage;
          }
          state.data.loading = false;
        }).catch((e) => {
          console.error(e);
          state.data.loading = false;
          state.data.error = false;
          state.data.errorMsg = '系统内部服务错误';
        });
      });
    },

    getCaptcha({ state }) {
      state.data.loading = true;
      const url = $url.getBackendApiUrl(`captcha/get?appKey=${state.phoneLogin.appKey}`);
      http.get(url, { responseType: 'arraybuffer' }).then((res) => {
        state.data.loading = false;
        if (res.data instanceof ArrayBuffer) {
          state.data.captchaUrl = 'data:image/jpg;base64,' + btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        }
      }).catch((e) => {
        console.error(e);
        state.data.loading = false;
        state.data.error = false;
        state.data.errorMsg = '获取验证码失败';
      });
    }
  }
};

export default router;
